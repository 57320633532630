export class LiveEditField {

  constructor(ui) {
    this.ui = ui
    this.targetElement = this.ui.dataset.target
    this.ui.addEventListener('input', () => {
      let value = this.ui.value
      $(`.${this.targetElement}`).html(value)
    })
  }
}