const itemsResultView = require("../templates/item_search.ejs")
const branchCell = require('../templates/branch_search.ejs')

export class AiSearchCardView {

  constructor(ui) {
    this.ui = ui
    this.fetchUrl = this.ui.dataset.fetch_url
    this.branchMarkers = []
    this.location = this.ui.dataset.location
    this.coordinates = this.location.split(',')
    O.on('MapView.created', (map) => {
      this.map = map
    })

    const resultsButton = this.ui.getElementsByClassName('result-button');

    [...resultsButton].forEach((element) => {
      element.addEventListener('click', () => {
        this.requestResults()
      })
    })

    document.getElementById('result-modal').addEventListener('hidden.bs.modal', () => {
      this.branchMarkers.forEach(marker => {
        marker.setMap(null)
      })
    });
  }

  requestResults() {
    Packs.application.globalFun.showLoadingWheel()
    fetch(this.fetchUrl).then(res => {
      return res.json()
    }).then(data => {
      const response = data
      this.branchMarkers = []
      $('#itemsResult').empty()
      response.branches.forEach(branch => {
        var marker = this.map.addBranchMarker(branch)
        this.branchMarkers.push(marker)
        branch.minsAway = Math.ceil(this.calculateDistance(new google.maps.LatLng(parseFloat(this.lat),
          parseFloat(this.lng)), new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude))))

        $('#itemsResult').append(branchCell({ branch: branch }))
      })

      response.items.forEach(item => {
        item.branch.minsAway = Math.ceil(this.calculateDistance(new google.maps.LatLng(parseFloat(this.lat),
          parseFloat(this.lng)), new google.maps.LatLng(parseFloat(item.branch.latitude),
            parseFloat(item.branch.longitude))))
      })

      // $('#items').append(itemsResultView({ response: response }))
      $('#itemsResult').append(itemsResultView({ response: response }))
      // $('#itemsResultModal').modal('show');
      // this.collapseButton.trigger('click')
      Packs.application.globalFun.hideLoadingWheel()
      this.map.moveTo(parseFloat(this.coordinates[0]), parseFloat(this.coordinates[1]))
      const myModal = new bootstrap.Modal(document.getElementById('result-modal'))
      myModal.show()
    }).catch(error => {
      console.error(error)
      Packs.application.globalFun.hideLoadingWheel()
    })
  }

  calculateDistance(from_location, to_location) {
    var distanceMeter = google.maps.geometry.spherical.computeDistanceBetween(from_location, to_location);
    var distanceKiloMeter = (distanceMeter / 1000);
    var timeDiff = ((distanceKiloMeter * 60) / 40)
    return (timeDiff < 2) ? 2 : timeDiff
  }
}