export class PhoneField {

  constructor(ui) {
    this.ui = ui
    this.ui.addEventListener('input', () => {
      let value = this.ui.value
      if (value.startsWith('00')) {
        value = value.replace(/^00+/, '+')
      }
      const phoneRegex = /^\+?[0-9]*$/
      if (!phoneRegex.test(value)) {
        console.log('Invalid phone number format')
      }
      this.ui.value = value
    })
  }
}